@tailwind base;
@tailwind components;
@tailwind utilities;

/* @import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro:wght@400;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Tenor+Sans:wght@400;600;700&display=swap'); */
/* @import url('https://fonts.googleapis.com/css2?family=Philosopher:ital,wght@0,400;0,700;1,400;1,700&family=Tenor+Sans&display=swap'); */

html,
body,
#root,
.app,
.content {
	height: 100%;
	width: 100%;
	font-family: 'Tenor Sans', 'Source Sans Pro', sans-serif;
}

.app {
	display: flex;
	position: relative;
}

::-webkit-scrollbar {
	width: 8px;
}

/* Track */
::-webkit-scrollbar-track {
	background: #e0e0e0;
	border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
	background: #888;
	border-radius: 20px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
	background: #555;
}
